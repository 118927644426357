@import '../../common.scss';

.container {
  padding: 8rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: $tint-color;
  position: relative;
  transition: all 2s;
  overflow: hidden;

  &.dark {
    background-color: $dark-color;
  }
  > .inArea {
    position: absolute;
    top: 10rem;
    bottom: 0;
  }
  > h2 {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: white;
  }
  > h1 {
    margin-top: 3rem;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    color: white;

    > span {
      color: $tint-color;
    }
  }
  > .button {
    display: flex;
    align-items: center;
    align-self: center;
    padding: 0 2rem;
    height: 4rem;
    border-radius: 2rem;
    background-color: rgba(white, 0.1);
    color: rgba(#fefffe, 0.5);
    line-height: 1;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 4rem;
  }
  > .wrapper {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > .marquee {
      color: $tint-color;
      font-size: 4rem;
      font-weight: bold;
      position: absolute;
      white-space: nowrap;
    }
    > .drone {
      width: 100%;
      max-width: 1194px * 0.5;
      z-index: 10;
    }
  }
}
