@import '../../common.scss';

.container {
  height: 100vh;
  min-height: 480px;
  position: relative;

  > video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
  > .wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(black, 0.7);

    > h1 {
      font-size: 3rem;
      font-weight: bold;
      text-align: center;
      color: white;
      z-index: 10;

      > span {
        color: $tint-color;
      }
    }
    > .arrow {
      background-image: url('../../assets/images/home/arrow-down.png');
      background-position: center;
      background-size: cover;
      width: 4rem;
      height: 4rem;
      position: absolute;
      bottom: 2rem;
      z-index: 10;
      animation: slowBounceUp 1s infinite ease-in;
      cursor: pointer;
    }
  }
}

@keyframes slowBounceUp {
  0% {
    margin-bottom: 8px;
  }
  50% {
    margin-bottom: 0;
  }
  100% {
    margin-bottom: 8px;
  }
}
