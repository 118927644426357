@use 'sass:color';
@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;
  background-color: $dark-color;

  > .wrapper {
    width: 100%;
    max-width: $max-width;
    padding: 8rem 4rem;
    display: flex;
    flex-direction: row;

    @include mobile {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    > .form {
      flex: 2;

      > h1 {
        font-size: 3rem;
        font-weight: bold;
        color: white;
      }
      > .row {
        display: flex;
        flex-direction: row;

        @include mobile {
          flex-direction: column;
        }
        &.bottom {
          margin-top: 2rem;
          align-items: flex-start;

          @include mobile {
            align-items: center;
          }
        }
        > .inputItem {
          margin-top: 1rem;
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 1rem;

          @include mobile {
            margin-left: 0;
          }
          &:first-child {
            margin-left: initial;
          }
          > label {
            font-size: 0.9rem;
            color: white;

            > span {
              color: $tint-color;
              font-size: 1rem;
            }
          }
          > input,
          > textarea {
            margin-top: 0.5rem;
            padding-left: 0.5rem;
            font-size: 1rem;
            color: $tint-color;
            background-color: #2d2d2d;
            outline: none;
            border: 1px solid transparent;
            border-radius: 4px;
            transition: all 0.2s;

            &:focus {
              border-color: $tint-color;
            }
          }
          > input {
            height: 3rem;
          }
          > textarea {
            padding-top: 0.5rem;
            height: 12rem;
            resize: none;
          }
        }
        > .agreeItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 1;

          > .checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $tint-color;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 4px;
            background-color: #2d2d2d;
            cursor: pointer;
          }
          > .privacyText {
            margin-left: 1rem;
            color: white;
            font-size: 1rem;
          }
        }
        > .button {
          display: flex;
          align-items: center;
          background-color: $tint-color;
          height: 3.2rem;
          color: white;
          font-size: 1rem;
          font-weight: bold;
          padding: 0 1.6rem;
          border-radius: 1.6rem;
          transition: all 0.2s;
          cursor: pointer;

          @include mobile {
            margin-top: 2rem;
          }
          &:hover {
            background-color: darken($tint-color, 10%);
          }
          > .arrow {
            margin-left: 3rem;
          }
        }
      }
    }
    > .side {
      flex: 1;
      margin-left: 2rem;
      background-image: url('../../assets/images/contact/mark.png');
      background-repeat: no-repeat;
      background-position: center 4rem;
      background-size: 72%;

      @include tablet_and_mobile {
        display: none;
      }
    }
  }
}
