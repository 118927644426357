@import '../common.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: black;
  overflow: hidden;

  > .wrapper {
    display: flex;
    align-self: center;
    width: 100%;
    max-width: $max-width;
    padding: 6rem 4rem;

    @include mobile {
      padding-right: 2rem;
      padding-left: 2rem;
      flex-direction: column;
    }
    > .left {
      flex: 0.8;

      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      > img {
        width: 16rem;
      }
      > h5 {
        margin-top: 4rem;
        font-size: 1rem;
        font-weight: bold;
        color: white;
      }
      > .sns {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;

        > .button {
          margin-left: 12px;

          &:first-child {
            margin-left: 0;
          }
          > a {
            height: 32px;
            padding: 0 1rem;
            background-color: $tint-color;
            font-size: 0.8rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            line-height: 1;
            border-radius: 4px;
          }
        }
      }
    }
    > .right {
      flex: 1;
      // margin-left: 4rem;

      @include mobile {
        margin-top: 4rem;
        margin-left: initial;
        text-align: center;
      }
      > .email {
        font-size: 1.8rem;
        text-decoration: underline;
        color: white;

        @include mobile {
          font-size: 1.4rem;
        }
      }
      > ul {
        margin-top: 4rem;
        display: flex;
        flex-direction: row;

        @include mobile {
          margin-top: 1rem;
          flex-direction: column;
        }
        > li {
          flex: 1;
          margin-left: 1rem;

          @include mobile {
            margin-top: 1rem;
            margin-left: initial;
            text-align: center;
          }
          &:first-child {
            margin-left: 0;
          }
          > h5 {
            font-size: 1rem;
            font-weight: bold;
            color: white;
          }
          > .contact {
            margin-top: 1rem;
            font-size: 0.9rem;
            font-weight: 300;
            color: white;
          }
        }
      }
    }
  }
  > .bottom {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    border-top: 1px solid rgba(white, 0.2);
    color: white;
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
}
