@use 'sass:color';
@import '../../common.scss';

.container {
  height: 90vh;
  min-height: 480px;
  position: relative;
  overflow: hidden;
  background-image: url('../../assets/images/home/video-placeholder.jpg');
  background-size: cover;
  background-position: center;

  @include mobile {
    height: 640px;
    min-height: initial;
  }
  > video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;

    > .dim {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      background-color: rgba(black, 0.5);
    }
  }
  > .wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(black, 0.5);

    > h1 {
      font-size: 6rem;
      font-weight: bold;
      text-align: center;
      color: white;

      @include tablet {
        font-size: 4rem;
      }
      @include mobile {
        font-size: 3rem;
      }
    }
    > p {
      margin-top: 2rem;
      font-size: 1.2rem;
      font-weight: 300;
      text-align: center;
      color: white;
      line-height: 2;
    }
    > .button {
      display: flex;
      align-items: center;
      align-self: center;
      padding: 0 2rem;
      height: 4rem;
      border-radius: 2rem;
      background-color: $tint-color;
      color: white;
      line-height: 1;
      font-size: 1rem;
      font-weight: bold;
      margin-top: 3rem;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
        background-color: darken($tint-color, 10%);
      }
      > .play {
        margin-left: 0.5rem;
      }
    }
  }
}
