@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;
  background-color: black;

  > .wrapper {
    width: 100%;
    max-width: $max-width;
    padding: 6rem 4rem;

    @include mobile {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    > h1 {
      font-size: 2rem;
      font-weight: bold;
      color: white;
      line-height: 1.8;

      > span {
        color: $tint-color;
      }
    }
    > .slider {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;

      @include mobile {
        flex-direction: column;
      }
      > .imageWrapper {
        flex: 1.2;

        @include tablet {
          flex: 0.5;
        }
        > img {
          width: 100%;
          display: none;

          &.active {
            display: block;
          }
        }
      }
      > .items {
        flex: 1;
        margin-left: 3rem;

        @include mobile {
          margin-top: 3rem;
          margin-left: initial;
        }
        > .item {
          margin-top: 2rem;

          &:first-child {
            margin-top: initial;
          }
          > .gauge {
            width: 100%;
            height: 2px;
            margin-bottom: 2px;
            background-color: #4d4d4d;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 0;
              bottom: 0;
              background-color: #4d4d4d;
            }
          }
          > h3 {
            margin-top: 1rem;
            font-size: 1.6rem;
            font-weight: bold;
            color: #4d4d4d;
            transition: initial;
          }
          > p {
            margin-top: 1rem;
            font-size: 1rem;
            font-weight: 300;
            color: #4d4d4d;
            line-height: 1.6;
            transition: initial;
          }
          &.active {
            > .gauge {
              height: 4px;
              margin-bottom: 0;

              &::after {
                width: 100%;
                background-color: $tint-color;
                transition: width 5s linear;
              }
            }
            > h3 {
              color: $tint-color;
              transition: all 0.2s;
            }
            > p {
              color: white;
              transition: all 0.2s;
            }
          }
        }
      }
    }
  }
}
