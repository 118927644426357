@import '../common.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  > .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $max-width;
    padding: 2rem 4rem;
    margin: 0 auto;

    @include mobile {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    .logo {
      width: 12rem;
    }
    > .gnb {
      display: flex;
      flex-direction: row;

      > li {
        color: white;
        font-size: 1.1rem;
        margin-left: 6rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
