// Colors
$tint-color: #f7a634;
$dark-color: #151515;

// Dimensions
$max-width: 1520px;

// Responsive
@mixin desktop {
  @media screen and (min-width: 1069px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 735px) and (max-width: 1068px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 734px) {
    @content;
  }
}
@mixin tablet_and_mobile {
  @media screen and (max-width: 1068px) {
    @content;
  }
}
