@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;
  background-color: $dark-color;

  > .wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: $max-width;
    padding: 8rem 4rem 12rem;

    @include tablet_and_mobile {
      flex-direction: column;
      padding-bottom: 4rem;
    }
    @include mobile {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    > .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      z-index: 10;

      @include tablet_and_mobile {
        align-items: center;
      }
      > h1 {
        font-size: 3rem;
        font-weight: bold;
        color: white;
      }
      > p {
        margin-top: 2rem;
        font-size: 1.5rem;
        color: white;
        line-height: 2;
        white-space: nowrap;

        @include tablet_and_mobile {
          white-space: initial;
          text-align: center;
        }
        > span {
          color: $tint-color;
        }
      }
      > .button {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 0 2rem;
        height: 4rem;
        border-radius: 2rem;
        background-color: rgba(white, 0.1);
        color: rgba(#fefffe, 0.5);
        line-height: 1;
        font-size: 1rem;
        font-weight: bold;
        margin-top: 4rem;
      }
    }
    > .right {
      flex: 1;
      position: relative;

      @include tablet_and_mobile {
        margin-top: 4rem;
      }
      > img {
        width: 100%;
        min-width: 36rem;
        max-width: 44rem;
        position: absolute;
        top: 0;
        right: -4rem;
        opacity: 0;
        transition: all 1s;

        @include tablet_and_mobile {
          min-width: initial;
          position: initial;
          display: none;
          margin: 0 auto;

          &.active {
            display: block;
          }
        }
        &.active {
          right: 0;
          opacity: 1;
        }
      }
    }
  }
}
